.contenedorInicio {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
}

.cuerpo {
  background-color: white;
}

body {
  overflow-x: hidden;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  z-index: 5;
}

.outer-div {
  background-color: #433B24;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150vh;
  width: 100vh;
}

.inner-div {
  background-color: #fff;
  width: 50vh;
  height: 30vh;
}

.outer-div2 {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  padding-bottom: 60px;
  padding-top: 40px;
}

.Servicios-Inicio {
  width: 100%;
  height: auto;

}

.inner-div2 {
  background-color: white;
  width: 100%;
  height: auto;
}

.outer-div3 {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.outer-div-Footer {
  background-color: transparent;
  width: 100vw;
  height: 15vh;

}

.divBotonCabañas {
  width: 266px;
  height: 59px;
  background-color: #433B24;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);

}

.divBotonCabañas:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.VerServicios {
  width: 100vw;
  height: auto;
  margin-bottom: 5%;
}

.VerExperiencias {
  width: 100vw;
  height: auto;

}

@media (max-width: 1023px) {
  .outer-div-Footer {
    height: 10vh;
  }
}

.image-container {
  width: 80vw; /* 80% del ancho de la pantalla */
  height: 20vh; /* 20% del alto de la pantalla */
  overflow: hidden; /* Para evitar que la imagen se desborde */
  margin: 0 auto; /* Centra el contenedor */
}

.inicio-image {
  width: 100%; /* La imagen ocupará el 100% del ancho del contenedor */
  height: 100%; /* La imagen ocupará el 100% del alto del contenedor */
  object-fit: cover; /* Asegura que la imagen se ajuste correctamente */
}