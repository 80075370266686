/* Header transparente */
.header-transparent {
  background: transparent;
  color: transparent;
  position: fixed;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Alinea los elementos desde la izquierda */
  padding: 0 1%; /* Margen reducido en los lados */
  height: 80px;
  align-items: center; /* Alinea verticalmente los elementos */
}

/* Header sólido */
.header-solid {
  background: white;
  position: fixed;
  z-index: 1000;
  width: 100%;
  display: flex;
  padding: 0 1%; /* Margen reducido en los lados */
  height: 9%;
  align-items: center; /* Alinea verticalmente los elementos */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  justify-content: flex-start; /* Alinea los elementos desde la izquierda */
}

/* Logo Container */
.logo-container-header {
  display: flex;
  align-items: center; /* Asegura que el logo esté centrado verticalmente */
  margin-right: 20px; /* Espacio entre el logo y el nav-container */
}

/* Logo */
.logo {
  width: 150px;
  height: auto;
}

.logo:hover {
  transform: scale(1.05); 
  transition: transform 0.3s ease-in-out; 
}

/* Nav Container */
.nav-container-header {
  display: flex;
  align-items: center; /* Centrado vertical para los links */
  justify-content: flex-start; /* Links alineados a la izquierda */
  margin-left: 2%; /* Espacio entre el logo y los links */
  border-left: 2px solid #94806D; /* Línea vertical */
  padding-left: 5%; /* Espacio interno entre la línea y los links */
}

/* Lista de navegación */
.nav-container-header ul {
  list-style: none;
  display: flex;
  gap: 10%; /* Espaciado entre los links */
  margin: 0;
  padding: 0;
}

/* Links */
.nav-container-header li a {
  text-decoration: none;
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.nav-container-header li a:hover {
  color: #94806D; /* Cambia el color cuando se pasa el mouse */
}

/* Sidebar Container */
.sidebar-container-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Button Container */
.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Asegura que el botón esté al extremo derecho */
  margin-left: auto; /* Desplaza este contenedor automáticamente hacia la derecha */
}
