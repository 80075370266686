.image-slider {
    background-color: #433B24;
    width: 100vw;
    position: relative;
}

.image-slider2 {
    background-color: white;
    width: 100vw;
    position: relative;
}

.image-slider .Fondo {
    position: absolute;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
}

.imagen_6_Inicio {
    width: 100%;
    height: 100%;
    display: block;
}

.Slider-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(67, 59, 36, 0.74);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.Slider-overlay p {
    font-size: 1.5em;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
}

.arrow-down {
    width: 45px;
    height: 45px;
}

.arrow-down:hover {
    transform: scale(1.4);
    transition: transform 0.3s ease-in-out;
}

.white-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-width: 500px;
    height: auto;
    padding: 40px;
    background: #433b249a;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.divBotonBuscar {
    width: 100%;
    max-width: 400px;
    height: 33px;
    background-color: #433B24;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

@media (max-width: 768px) {
    .divBotonBuscar {
        width: 80%;
    }
}

.divBotonBuscar:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

.TextoExperiencias {
    font-family: 'Montserrat';
    font-size: 1.5em;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.TextoExperiencias .Destacado {
    font-family: 'Montserrat';
    font-size: 1.5em;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    font-weight: bold;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(67, 59, 36, 0.3);
}

.image-container {
    width: 80%;
    height: 20%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background-color: white;
}

.image-container2 {
    width: 80%;
    height: 10%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background-color: white;
}
.inicio-video {
    width: 100%;
    height: 50vh; /* 50% de la altura de la ventana del navegador */
    object-fit: cover; /* El video se ajustará para cubrir todo el contenedor */
    object-position: center center; /* Centra el video en el contenedor */
    transform: translateY(+20%);
}






