.contenedorFormularioPago {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100vh; /* Asegura que el contenido se expanda si es necesario */
    background-color: #82673B;
}


.cuerpoFormularioPago {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Asegura que el contenido se expanda si es necesario */
    flex-direction: column;

}


body {
    overflow-x: hidden;
}

.fixed-header-FormularioPago {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vh;
    z-index: 5;
}

.ContenedorImagenFormulario {
    position: relative;
    display: flex;
    width: 100vw;
    min-height: 100vh; /* Asegura que el contenido se expanda si es necesario */
}

.ContenedorImagenFormulario img {
    width: 100%;
    min-height: 100vh; /* Asegura que el contenido se expanda si es necesario */
    object-fit: cover;
    max-height: 140vh;
}


.formPayWrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%; 
    min-height: 100vh; /* Asegura que el contenido se expanda si es necesario */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7%;
    padding-bottom: 10%;
}

.outer-div-Footer {
    background-color: transparent;
    width: 100vw;
    height: 15vh;
  
  }