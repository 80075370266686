.contenedorReserva {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    background-color: #82673B;
}
.contenedorCompra {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    background-color: white;
}

.cuerpoReserva {
    background-color: white;
}

body {
    overflow-x: hidden;
}

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vh;
    z-index: 5;
}