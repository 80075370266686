/* Estilo general para el carrito */
.carrito-icon {
  font-size: 170%; /* Tamaño base del ícono */
  transition: font-size 0.3s ease-in-out; /* Suaviza el cambio de tamaño */
  color: #94806D;
}
.sidebar-cart-container {
  width: 30%; /* Ancho del sidebar */
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: white;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.sidebar-cart-header {
  padding-bottom: 20px;
}

.sidebar-cart-body {
  overflow-y: auto;
  flex: 1;
}

.product-list {
  margin-bottom: 20px;
}

.order-summary {
  margin-bottom: 20px;
}

.pay-button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pay-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .sidebar-cart-container {
    width: 100%;
    height: auto;
    position: relative;
  }
}
